import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="JrZoo" />
    <h2>Hello &amp; welcome!</h2>
    <p>We've been helping businesses share their message and meet their goals online for over a decade.</p>
  </Layout>
)

export default IndexPage
